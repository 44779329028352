
import { defineComponent, computed, ref, onMounted, nextTick, watch } from "vue";
import useComponentv2 from '@/composables/v2/useComponent';
import Table from '@/components/tables/v2/Table.vue';
import useLanguage from '@/composables/v2/useLanguage';
import ModalT from "@/components/modals/default/v2/Modal.vue";
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";
import Icon from '@/components/icons/Icon.vue';
import { Modal } from "bootstrap";
import ModalFlow from "@/components/modals/default/v2/ModalShipmentLinesFlow.vue";

export default defineComponent({
  name: "",
  components: {
      Table,
      ModalT,
      ToolbarActions,
      Icon,
      ModalFlow
  },
  props: {
      componentName: String,
      componentTitle: String,
      displayMethod: String,
      componentType: String,
      componentSettings: Object,
      componentTypeActions: Object,
      dispatchActions: Object,
      componentReload: Boolean,
      componentRedirect: Object,
      currentData: String,
      componentFilter: Object,
      componentToolbar: Object,
      modalId: String,
      currentId: String,
      routeId: String,
  },
  setup(props, { emit }) {
    const { initComponent, componentData, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, modalRef, reloadComponent, renderModal, renderAmount, renderId } = useComponentv2();
    const { fieldName, fieldValue, fieldType, initLanguageModal } = useLanguage();

    initComponent({
      componentName: props.componentName, 
      displayMethod: props.displayMethod, /* new or existing */
      componentType: props.componentType, /* form or table */
      componentSettings: props.componentSettings,
      componentTypeActions: props.componentTypeActions,
      dispatchActions: props.dispatchActions,
      componentReload: props.componentReload,
      componentRedirect: props.componentRedirect,
      currentData: props.currentData,
      componentFilter: props.componentFilter,
      componentToolbar: props.componentToolbar,
      currentId: props.currentId,
      modalId: props.modalId,
    });

    onMounted(() => {
      if (props.displayMethod == 'existing') {
        const modal = document.getElementById(props.modalId!)
        const element = Modal.getOrCreateInstance(modal)
        element.show()
      }
    });

    return {
      componentData,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      modalRef,
      fieldName, fieldValue, fieldType, initLanguageModal,
      renderId,
      reloadComponent,
      renderModal,
      renderAmount,
    };
  }
});
